* {
    font-family: Rubik, Verdana, Geneva, Tahoma, sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
}

#inner-content > div {
    margin: 50px 20px;
}

body, html {
    background-color: rgb(245, 244, 243);
    margin: 0;
    padding: 0;
}

:root {
    --content-width: 1000px;
}


#name-card-container {
    width: 100%;
    
    background-image: url(../img/banner.jpg);
    background-size: cover;
    background-position: center center;

    #name-card {
        margin: 0 auto;
        color: white;
        padding: 10px 0;
        display: flex;
        flex-direction: column;

        a {
            text-decoration: none;
        }
        
        h1 {
            z-index: 2;
            margin: 0;
            text-align: center;
            font-size: 70px;
            font-weight: normal;

            color: white;
            
        }

        #social {
            list-style-type: none;
            display: flex;
            flex-direction: row;
            margin: 0 auto;
            padding-left: 0;
            justify-content: center;

            li {
                padding: 10px;
    
                img {
                    height: 40px;
                    filter: invert(1);
                }
            }
        }    
    }

    @media screen and (max-width: 1000px) {
        height: auto;
        
        #name-card {
            height: auto;
        }

        #name-card h1 {
            font-size: 10vw;
        }
    }
}

#content {
    margin: -40px auto 0 auto;

    width: 100%;
    max-width: var(--content-width);
}

$link-color: rgb(221, 88, 11);

a {
    color: $link-color;

    &:visited {
        color: darken($link-color, 20%);
    }
}

#post-body {
    font-size: 1.2em;
    margin-top: 80px !important;

    * {
        line-height: 1.5;
    }

    #post-header {
        h1 {
            margin-block-end: 0;
        }
    }

    img {
        max-width: 100%;
    }
}

sub {
    color: #555;
}

// Syntax highlighting

@import "pygments.github.css";

figure.highlight, div.highlighter-rouge {
    overflow-x: auto;

    margin: 0;
    padding: 20px;
    background-color: #ededed;

    pre {
        margin: 0;
    }

    * {
        font-family: 'Fira Code', monospace !important;
        font-variant-ligatures: none;
    }
}

code {
    background-color: #ededed;
    font-size: 0.9em;
    margin-left: 2px;
    margin-right: 2px;
    font-family: 'Fira Code', monospace !important;
    font-variant-ligatures: none;
}

b, h3, strong {
    font-weight: 500;
}
